import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import { IApiResponse } from "../IApi";

export enum userTypes {
  admin = "admin",
  user = "user",
  operator = "operator"
}

export type userType = keyof typeof userTypes;

export interface AddUserDto {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  type?: userType;
}

export interface EditUserDto {
  email: string;
  fullName: string;
  id: number;
}

export interface AddUserModalDto extends AddUserDto {
  avatar?: File;
}

export interface ITemplatesDataResponse extends IApiResponse {
  data: IUserAccount;
}
